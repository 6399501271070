.list {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 16px 2px;

	@media (--md) {
		&.four {
			grid-template-columns: repeat(4, 1fr);
		}

		&.three {
			grid-template-columns: repeat(3, 1fr);
		}
	}
}
