.filterBarContainer {
	position: static;
	top: var(--filter-offset);
	padding-bottom: var(--space-sm);
	border-radius: 8px;
	transition: all 0.2s ease;
	will-change: top, opacity;
	z-index: 30;

	@media (--md) {
		max-width: calc(100% - var(--space-md) * 2);
		max-height: 66px;
		position: sticky;
		margin-inline: auto;
		padding-bottom: 0;
	}
}

:global(body.scroll-locked) {
	& .filterBarContainer {
		opacity: 0;
	}
}

.active {
	background-color: transparent;
	box-shadow: none;
	z-index: 50;

	@media (--md) {
		background-color: var(--primary-white);
		box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
	}
}

.activeFilters {
	position: relative;
	z-index: 40;

	&.isSticky {
		z-index: 0;
	}
}

.filterBar {
	padding-inline: 16px;
	display: grid;
	align-items: center;
	grid-template-columns: repeat(2, 1fr);

	& > * {
		&:last-child {
			justify-self: end;
		}
	}

	& .center {
		display: none;
		justify-self: center;

		@media (--md) {
			display: block;
		}
	}

	@media (--md) {
		padding-block: 20px;
		padding-inline: 32px;
		grid-template-columns: repeat(3, 1fr);
	}
}

/* Modal styles */
.modalContent {
	padding: 0;
	overflow: hidden;
}

.modalHeader {
	height: 82px;
	padding: 30px;
	padding-bottom: 20px;
	border-bottom: 1px solid var(--primary-greige);
}

.close {
	position: absolute;
	top: 20px;
	right: 20px;
}

.modalFilters {
	overflow: auto;
	flex-shrink: 1;
	height: 100%;
	max-height: 100%;
}

.modalFooter {
	height: 128px;
	position: sticky;
	bottom: 0;
	margin: 0;
	margin-top: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0 -2px 4px 0 rgb(0 0 0 / 5%);
}

.modalTrigger {
	width: fit-content;
	padding: 0;
	display: flex;
	align-items: center;
	gap: 6.5px;
	background-color: transparent;
	border: none;
	cursor: pointer;
}

/* Filter styles */
.filterValues {
	min-width: fit-content;
	padding: 0;
	display: flex;
	gap: var(--space-xs);
	flex-wrap: wrap;
}

.filterToggle {
	height: 42px;
	padding-block: var(--space-xs);
	padding-inline: calc(var(--space-sm) - 2px);
	display: flex;
	align-items: center;
	background-color: transparent;
	border: 1px solid var(--color-border);
	cursor: pointer;
	border-radius: fluidSize(4px, 4px);

	& .content {
		display: flex;
		align-items: center;
		gap: var(--space-2xs);
		line-height: 1;
	}

	&:has(:focus-visible) {
		outline: 2px solid var(--color-border-focus);
		outline-offset: 2px;
	}

	& .count {
		@extend %typo-utility-5;
	}

	&:has(input:checked) {
		background-color: var(--secondary-blue);
		color: var(--primary-white);

		& .count {
			color: var(--primary-grey);
		}
	}
}

.actions {
	height: 20px;
	display: flex;
	align-items: center;
	gap: var(--space-xs);
}

.swatch {
	width: 20px;
	height: 20px;
	flex-shrink: 0;
	border-radius: 50%;
	display: block;
}

/* Accordion styles */

.filterAccordion {
	padding: 30px;
	gap: 0;
}

.filterAccordionItem {
	padding-top: var(--space-md);
	padding-bottom: var(--space-md);
	border-bottom: 1px solid var(--primary-greige);

	&:first-child {
		padding-top: 0;
	}
}

.filterAccordionTrigger {
	margin: 0;
	padding: 0;
}

.filterAccordionContent {
	padding-top: var(--space-xs);
}

.viewport {
	--modal-width: min(700px, 100vw);
}

.filterScrollArea {
	height: 100%;
	max-height: 100%;
	padding-bottom: 0;
}

.bar {
	margin-right: var(--space-sm);
}

/* stylelint-disable-next-line no-duplicate-selectors */
.activeFilters {
	max-width: fit-content;
	margin-inline: 16px;
	margin-bottom: var(--space-sm);
	display: flex;
	align-items: center;
	gap: var(--space-xs);
	flex-wrap: wrap;
	grid-column: span 2;
	grid-row: 2;

	@media (--md) {
		margin-inline: 52px;
		margin-top: -12px;
		margin-bottom: var(--space-lg);
		gap: 8px;
		grid-column: span 3;
	}

	& .activeFilter {
		border: 1px solid var(--primary-dark-blue-005);
		background-color: var(--primary-dark-blue-005);
		padding: 8px 8px 8px 12px;
		border-radius: 4px;
		color: var(--primary-dark-blue);
		display: flex;
		align-items: center;
		gap: 4px;

		@media (hover: hover) {
			&:hover {
				background-color: var(--primary-dark-blue-01);
			}
		}
	}

	& .filterText {
		@extend %typo-utility-5;

		background: transparent;

		@media (--md) {
			@extend %typo-utility-4;
		}
	}
}

.filterTrigger {
	@extend %typo-utility-4;

	@media (--md) {
		@extend %typo-utility-3;
	}
}

.filterSkeleton {
	@media (--max-md) {
		margin-bottom: 19px;
	}
}
